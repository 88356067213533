<template>
  <cs-page>
    <cs-banner :type="1" />
    <WhySelect />
    <QuestionSolution @scrollview="scrollview" />
    <CooperativePartner />
    <CustomerApply ref="CustomerApply" />
    <CustomerApplyPhone v-if="getBrowser == 'phone' "/>
  </cs-page>
</template>
<script>
import { components } from "@/plugins/utils";
export default {
  ...components({
    home: [
      "WhySelect",
      "QuestionSolution",
      "CooperativePartner",
      "CustomerApply",
      "CustomerApplyPhone"
    ],
  }),
  data() {
    return {};
  },
  methods: {
    scrollview() {
      if(this.getBrowser == 'pc'){
         this.$refs.CustomerApply.openClick();
      }
     
    },
  },
};
</script>
<style lang="less" scoped>
</style>